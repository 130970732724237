<template>
    <div class="homes-after-construction">
        <div class="homes-after-construction-cover cover container-fluid px-0 mt-5">
            <div class="container">
                <div class="row mx-2">
                    <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                        <h4 class="text-white mx-5 mt-5 mb-4">След ремонт</h4>
                        <p class="text-white mx-5 my-4">
                            Предлаганата услуга е разновидност на стандартното почистване. Целта е да се премахнат остатъчни строителни отпадъци, за да може помещението да се приведе във вид, готов за използване.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-5 mb-3">
            <h3>Почистване след ремонт</h3>
            <p>Услугата &quot;<strong>Почистване след ремонт</strong>&quot; е предазначена за жилища и къщи, след освежителни строителни дейности, така че да се приведат във вид, готов за нанасяне. Обикновено основната работа е свързана с дълбоко почистване на прах, изнасяне на груби остатъци от замазки и шпакловки, боя, лепило. По този начин се дава възможност да се продължи с по-фините дейности.<br />
            <br />
            В следващия си етап тази услуга е подобна на основното почистване, като включва почистване на прозорци и дограма, подови настилки, бани и санитария, полиране на повърхности, мебели, меки подови настилки, осветителни тела и други по договорка.<br />
            <br />
            <strong>Почистването след ремонт</strong> може да се извършва на етапи, в зависимост от това до къде е стигнал ремонтът и какъв е планът му. Целта е да се предотврати натрупването на големи количества прах и строителни отпадъци. При почистването използваме специализирани препарати, които са предназначени за различен тип повърхност и щадят мебелите от изхабяване и нараняване.<br />
            <br />
            Необходимо е посещение на място за по-точна преценка на степента на замърсеност, вид на ремонта и съответните активности, както и повърхностите, които да бъдат включени.
            <br /> <br>
            Това, което ние ще извършим за няколко часа на вас ще отнеме цяла седмица. Отдайте се на заслужена почивка, докато нашият екип работи за вас.<br /></p>
        </div>
        <ServiceForm :servicePrice="4.5"/>
    </div>
</template>
<script>
import ServiceForm from '../../../ServiceForm'

export default {
    name: "HomesAfterConstruction",
    components: {
        ServiceForm,
    }
}
</script>